import React from "react";
import { Connection } from '@solana/web3.js';
import Clock from "./Clock";
import { getAccountsByCreatorAddress } from "../../helpers/InstaplexContracts";
import { RPC_URL, COLLECTIONS } from "../../config";


const _collectionNameMap = new Set();
COLLECTIONS.map(x => x.name).forEach(x => _collectionNameMap.add(x));
console.log(_collectionNameMap);

const getMetaData = async (rawData) => {
  const resultArr = Promise.all(rawData.map(async metadata => {
    const trimmedName = metadata[0].data.name.toString().replace(new RegExp('\u0000', "ig"), "");
      if (
        metadata[0].hasOwnProperty('data') &&
        metadata[0].data.hasOwnProperty('uri') &&
        metadata[0].data.uri.includes('arweave') &&
        _collectionNameMap.has(trimmedName)) {

          const rawResponse = await fetch(metadata[0].data.uri);
          const json = await rawResponse.json();
          return {...json, explorerUrl: `https://explorer.solana.com/address/${metadata[0].mint}?cluster=devnet` };
      }
  }));

  return (await resultArr).filter(result=> result!==undefined)
}

export default function Responsive() {
    const [nftData, setNftData] = React.useState([]);
    const [displayedNFTs, setDisplayedNFTs] = React.useState([]);
    const [height, setHeight] = React.useState(0);

    React.useEffect(()=>{
        (async()=>{
            const connection = new Connection(RPC_URL, 'confirmed');

            const creators = new Set();
            COLLECTIONS.forEach(coll => creators.add(coll.artist));
            const accounts = [];
            for(const x of creators) {
              const [res, _] = await getAccountsByCreatorAddress(x, connection);
              res.map(x=> accounts.push(x));
            }
            const res = await getMetaData(accounts);
            setNftData(res);
            setDisplayedNFTs(res.slice(0,8));
        })();
    }, []);

    const loadMore = () => {
        let start = displayedNFTs.length
        let end = displayedNFTs.length+4
        setDisplayedNFTs([...displayedNFTs, ...nftData.slice(start, end)]);
    }

    function onImgLoad({target:img}) {
        let currentHeight = height;
        if(currentHeight < img.offsetHeight) {
            setHeight(img.offsetHeight);
        }
    }
    

  return (
    <div className='row'>
        {displayedNFTs.map( (nft, index) => (
            <div key={index} className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4">
                <div className="nft__item m-0">
                    {/* { nft.deadline &&
                        <div className="de_countdown">
                            <Clock deadline={nft.deadline} />
                        </div>
                    } */}
                    <div className="author_list_pp">
                        <span onClick={()=> window.open("#", "_self")}>                                    
                            <img className="lazy" src={"./img/author/author-9.jpg"} alt=""/>
                            <i className="fa fa-check"></i>
                        </span>
                    </div>
                    <div className="nft__item_wrap" style={{height: `${height}px`}}>
                        <span>
                            <img onLoad={onImgLoad} src={nft.image} className="lazy nft__item_preview" alt=""/>
                        </span>
                    </div>
                    <div className="nft__item_info">
                        <span onClick={()=> window.open(nft.image, "_self")}>
                            <h4>{nft.symbol}</h4>
                        </span>
                        <div className="nft__item_price">
                            {/* <span>{nft.symbol}</span> */}
                            {nft.description}
                        </div>
                        <div className="nft__item_action">
                            <span onClick={()=> window.open(nft.explorerUrl, "_blank")}>View in Explorer</span>
                        </div>
                        {/* <div className="nft__item_like">
                            <i className="fa fa-heart"></i><span>0</span>
                        </div> */}
                    </div> 
                </div>
            </div>  
        ))}
        { displayedNFTs.length !== nftData.length &&
            <div className='col-lg-12'>
                <div className="spacer-single"></div>
                <span onClick={loadMore} className="btn-main lead m-auto">Load More</span>
            </div>
        }
    </div>              
    );
}
