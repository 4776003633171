export const COLLECTIONS = [
    { 
        name: "SolBoiss", 
        artist: "5XoijkWyi9Dsd7jNyrQxryDrPYoHBjErMYrYhuXjbYHX",
        nftCount: 69,
        artistName: "RoodRa",
        symbol: "SBOIS",
        description: "This is the first NFT from SOLBOIS",
        priceMint: 0.1,
        creatorId: "5XoijkWyi9Dsd7jNyrQxryDrPYoHBjErMYrYhuXjbYHX",
        accountId: "8sUx4Qm1bh5D3nwKQRmYBR7eHLKypUQdPW9HGw9AB69H",
        authorImg: "",
        img: "https://instaplexdev.s3.amazonaws.com/E5r34Xg5udmtLg9Nu6zipjK46iLBKbzto3dJ9kHeUSJe.png",
        
    },
    { 
        name: "SolMooon", 
        artist: "5XoijkWyi9Dsd7jNyrQxryDrPYoHBjErMYrYhuXjbYHX",
        nftCount: 127,
        symbol: "SMOON",
        artistName: "sXic4",
        description: "The moon takes over",
        priceMint: 0.1,
        creatorId: "5XoijkWyi9Dsd7jNyrQxryDrPYoHBjErMYrYhuXjbYHX",
        accountId: "7s5br37tX8JpTwvZ19PmiqQd4VHz6KHvJSf1V9x45xJo",
        authorImg: "",
        img: "https://instaplexdev.s3.amazonaws.com/E5r34Xg5udmtLg9Nu6zipjK46iLBKbzto3dJ9kHeUSJe.png",
    },
    { 
        name: "Starbois", 
        artist: "5XoijkWyi9Dsd7jNyrQxryDrPYoHBjErMYrYhuXjbYHX",
        priceMint: 0.1,
        description: "Here comes the starbois",
        symbol: "STB1",
        artistName: "Brown BOI",
        nftCount: 100,
        creatorId: "5XoijkWyi9Dsd7jNyrQxryDrPYoHBjErMYrYhuXjbYHX",
        accountId: "5UDGGvW7rTYSFBm9u1Mqo9kFo2LuWPBtzSAUTNobP5wM",
        authorImg: "",
        img: "https://instaplexdev.s3.amazonaws.com/E5r34Xg5udmtLg9Nu6zipjK46iLBKbzto3dJ9kHeUSJe.png",
    }
]

export const PROGRAM_ID = "4S6fK7b2E52qC1RaX6XqzarMF5SCfL1has6igidFN9uj";

export const RPC_URL = "https://api.devnet.solana.com";
