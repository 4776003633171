import React, { Component } from "react";
import { Connection, PublicKey } from '@solana/web3.js';
import * as borsh from 'borsh';
import Slider from "react-slick";
import { Link } from "react-router-dom";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { COLLECTIONS, RPC_URL } from "../../config";
import { 
  CreateCollectionProfile, 
  CreateCollectionSchema
} from "../../helpers/InstaplexContracts";

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

const settings = (len) => {
  const slidesToShow = len >= 4 ? 4 : len;
  const layout = {
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  }

  return layout
};

export default function Responsive() {

  const [accountDetails, setAccountDetails] = React.useState([]);

  React.useEffect(()=>{
    const publicKeyArray = COLLECTIONS.map(collection=> new PublicKey(collection.accountId));
    (async ()=> {
      const connection = new Connection(RPC_URL, 'confirmed');
      const accountInfo = await connection.getMultipleAccountsInfo(publicKeyArray);

      let accountsInfoDetails = [];
      accountInfo.map(info => {
        const collectionDetails = borsh.deserialize(
          CreateCollectionSchema,
          CreateCollectionProfile,
          info.data
        );

        accountsInfoDetails.push({
          name: collectionDetails.name,
          totalSupply: collectionDetails.total_supply.toString(),
          totalMinted: collectionDetails.total_minted.toString()
        })
      });

      setAccountDetails(accountsInfoDetails);
    })();
  },[]);

    return (
      <div className='nft'>
        <Slider {...settings(accountDetails.length)}>
          {accountDetails.map((details, iter) => 
            <CustomSlide className='itm' key={iter+details.name} index={iter}>
              <div className="nft_coll">
                  <div className="nft_wrap">
                      <span><img src="./img/collections/coll-3.jpg" className="lazy img-fluid" alt=""/></span>
                  </div>
                  <div className="nft_coll_pp">
                      <span><img className="lazy" src="./img/author/author-2.jpg" alt=""/></span>
                      <i className="fa fa-check"></i>
                  </div>
                  <div className="nft_coll_info">
                      <Link to={`/collection/${iter}`}><span><h4>{details.name}</h4></span></Link>
                      <span>{details.totalMinted}/{details.totalSupply} Minted</span>
                  </div>
              </div>
            </CustomSlide>
          )}
        </Slider>
      </div>
    );
}
