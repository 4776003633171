import React from 'react';
// import { Router, Location, Redirect } from '@reach/router';
import ScrollToTopBtn from './menu/ScrollToTop';
import Header from './menu/header';
import Home from './pages/home';
import Home1 from './pages/home1';
import Home2 from './pages/home2';
import Explore from './pages/explore';
import Explore2 from './pages/explore2';
import Helpcenter from './pages/helpcenter';
import Rangking from './pages/rangking';
import Colection from './pages/colection';
import ItemDetail from './pages/ItemDetail';
import Author from './pages/Author';
import Wallet from './pages/wallet';
import Login from './pages/login';
import LoginTwo from './pages/loginTwo';
import Register from './pages/register';
import Price from './pages/price';
import Works from './pages/works';
import News from './pages/news';
import Create from './pages/create';
import Auction from './pages/Auction';
import Activity from './pages/activity';
import Contact from './pages/contact';
import ElegantIcons from './pages/elegantIcons';
import EtlineIcons from './pages/etlineIcons';
import FontAwesomeIcons from './pages/fontAwesomeIcons';
import Accordion from './pages/accordion';
import Alerts from './pages/alerts';
import Progressbar from './pages/progressbar';
import Tabs from './pages/tabs';

import { createGlobalStyle } from 'styled-components';

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import {
  getBitpieWallet,
  getCoin98Wallet,
  getLedgerWallet,
  getMathWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolongWallet
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
// import { Switch } from 'antd';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

const useWalletHook = () => {
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = React.useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets imports all the adapters but supports tree shaking --
  // Only the wallets you want to support will be compiled into your application
  const wallets = React.useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getLedgerWallet(),
      getSolletWallet({ network }),
      getSolongWallet(),
      getMathWallet(),
      getCoin98Wallet(),
      getBitpieWallet(),
      getSlopeWallet(),
    ],
    [network]
  );

  // TODO: Add toaster for Errors
  const onError = React.useCallback(
    (error) => console.log(error),
    []
  );

  return [endpoint, wallets, onError];
}

// const PosedRouter = ({ children }) => {
//   const [endpoint, wallets, onError] = useWalletHook();

//   return (
//     <Location>
//       {({ location }) => (
//         <div id='routerhang'>
//           <div key={location.key}>
//             <Router location={location}>
//               {/* <ConnectionProvider endpoint={endpoint}> */}
//               {/* <WalletProvider wallets={wallets} onError={onError} autoConnect> */}
//               {children}
//               {/* </WalletProvider> */}
//               {/* </ConnectionProvider> */}
//             </Router>
//           </div>
//         </div>
//       )}
//     </Location>
//   )
// };

// const app2 = () => (
//   <div className="wraper">
//     <GlobalStyles />
//     <WalletModalProvider>
//       <Header />
//     </WalletModalProvider>
//     <PosedRouter>
//       <ScrollTop path="/">
//         <Home exact path="/">
//           <Redirect to="/home1" />
//         </Home>
//         <Home1 path="/home1" />
//         <Home2 path="/home2" />
//         <Explore path="/explore" />
//         <Explore2 path="/explore2" />
//         <Helpcenter path="/helpcenter" />
//         <Rangking path="/rangking" />
//         <Colection path="/colection" />
//         <ItemDetail path="/ItemDetail" />
//         <Author path="/Author" />
//         <Wallet path="/wallet" />
//         <Login path="/login" />
//         <LoginTwo path="/loginTwo" />
//         <Register path="/register" />
//         <Price path="/price" />
//         <Works path="/works" />
//         <News path="/news" />
//         <Create path="/create" />
//         <Auction path="/Auction" />
//         <Activity path="/activity" />
//         <Contact path="/contact" />
//         <ElegantIcons path="/elegantIcons" />
//         <EtlineIcons path="/etlineIcons" />
//         <FontAwesomeIcons path="/fontAwesomeIcons" />
//         <Accordion path="/accordion" />
//         <Alerts path="/alerts" />
//         <Progressbar path="/progressbar" />
//         <Tabs path="/tabs" />
//       </ScrollTop>
//     </PosedRouter>
//     <ScrollToTopBtn />

//   </div>
// );

const App = () => {
  const [endpoint, wallets, onError] = useWalletHook();
  
  return (
    <div className="wraper">
      <ScrollTop>
      <GlobalStyles />
      <Router>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} onError={onError} autoConnect>
          <WalletModalProvider>
            <Header />
          </WalletModalProvider>
          <Switch>
            <Route exact path="/"><Home1 /></Route>
            <Route path="/collection/:collectionId"><ItemDetail /></Route>
          </Switch>
          <ScrollToTopBtn />
          </WalletProvider>
        </ConnectionProvider>
      </Router>
      </ScrollTop>
    </div>
  )
};

export default App;
