import { Connection, PublicKey } from '@solana/web3.js';
import * as borsh from 'borsh';
import { RPC_URL } from '../config';

import {
	findProgramAddress,
	Metadata,
	METADATA_SCHEMA,
	programIds,
	toPublicKey,
} from "@instaplex/common";
import {
	TransactionInstruction
} from "@solana/web3.js";
import base58 from 'bs58';

export class CreateCollectionProfile {
	name = '';
	total_minted = 0;
	total_supply = 0;

	constructor(
		fields
		// : {
		//     name: string,
		//     total_minted: number,
		//     total_supply: number,
		// } | undefined = undefined
	) {
		if (fields) {
			this.name = fields.name;
			this.total_minted = fields.total_minted;
			this.total_supply = fields.total_supply;
		}
	}
}

export const CreateCollectionSchema = new Map([
	[CreateCollectionProfile, {
		kind: 'struct', fields: [
			['name', 'string'],
			['total_minted', 'u128'],
			['total_supply', 'u128'],
		]
	}]
]);

const MAX_NAME_LENGTH = 32;
const MAX_URI_LENGTH = 200;
const MAX_SYMBOL_LENGTH = 10;
const MAX_CREATOR_LEN = 32 + 1 + 1;
const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
	'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'
);

const METADATA_SIGNATURE = Buffer.from([7]); //now thats some voodoo magic. WTF metaplex? XD

const extendBorsh = () => {
	(borsh.BinaryReader.prototype).readPubkey = function () {
		const reader = this;
		const array = reader.readFixedArray(32);
		return new PublicKey(array);
	};

	(borsh.BinaryWriter.prototype).writePubkey = function (value) {
		const writer = this;
		writer.writeFixedArray(value.toBuffer());
	};

	(borsh.BinaryReader.prototype).readPubkeyAsString = function () {
		const reader = this;
		const array = reader.readFixedArray(32);
		return base58.encode(array);
	};

	(borsh.BinaryWriter.prototype).writePubkeyAsString = function (
		value,
	) {
		const writer = this;
		writer.writeFixedArray(base58.decode(value));
	};
};

extendBorsh();


export function signMetadataInstruction(
	metadata,
	creator,
) {
	const data = METADATA_SIGNATURE;

	const keys = [
		{
			pubkey: metadata,
			isSigner: false,
			isWritable: true,
		},
		{
			pubkey: creator,
			isSigner: true,
			isWritable: false,
		},
	];
	return new TransactionInstruction({
		keys,
		programId: TOKEN_METADATA_PROGRAM_ID,
		data,
	});
}

export const signAllUnverifiedNFTs = async (unverifiedAccounts, publicKey) => {
	const metadataProgramId = programIds().metadata;
	const instructions = [];

	for (let i = 0; i < Math.min(unverifiedAccounts.length, 20); i++) {
		const mint = unverifiedAccounts[i][0].mint;
		const metadataAccount = (
			await findProgramAddress(
				[
					Buffer.from('metadata'),
					toPublicKey(metadataProgramId).toBuffer(),
					toPublicKey(mint).toBuffer(),
				],
				toPublicKey(metadataProgramId),
			)
		)[0];
		instructions.push(
			signMetadataInstruction(toPublicKey(metadataAccount), publicKey)
		)
	}
	return instructions;
};

async function getProgramAccounts(
	connection,
	programId,
	configOrCommitment,
) {
	const extra = {};
	let commitment;
	//let encoding;

	if (configOrCommitment) {
		if (typeof configOrCommitment === 'string') {
			commitment = configOrCommitment;
		} else {
			commitment = configOrCommitment.commitment;
			//encoding = configOrCommitment.encoding;

			if (configOrCommitment.dataSlice) {
				extra.dataSlice = configOrCommitment.dataSlice;
			}

			if (configOrCommitment.filters) {
				extra.filters = configOrCommitment.filters;
			}
		}
	}

	const args = connection._buildArgs([programId], commitment, 'base64', extra);
	const unsafeRes = await (connection)._rpcRequest(
		'getProgramAccounts',
		args,
	);
	//console.log(unsafeRes)
	const data = (
		unsafeRes.result
		//    as Array<{
		// 	account: AccountInfo<[string, string]>;
		// 	pubkey: string;
		//   }>
	).map(item => {
		return {
			account: {
				// TODO: possible delay parsing could be added here
				data: Buffer.from(item.account.data[0], 'base64'),
				executable: item.account.executable,
				lamports: item.account.lamports,
				// TODO: maybe we can do it in lazy way? or just use string
				owner: item.account.owner,
			},
			pubkey: item.pubkey,
		};
	});

	return data;
}


export async function getAccountsByCreatorAddress(creatorAddress, connection) {
	const metadataAccounts = await getProgramAccounts(
		connection,
		TOKEN_METADATA_PROGRAM_ID.toBase58(),
		{
			filters: [
				{
					memcmp: {
						offset:
							1 + // key
							32 + // update auth
							32 + // mint
							4 + // name string length
							MAX_NAME_LENGTH + // name
							4 + // uri string length
							MAX_URI_LENGTH + // uri*
							4 + // symbol string length
							MAX_SYMBOL_LENGTH + // symbol
							2 + // seller fee basis points
							1 + // whether or not there is a creators vec
							4 + // creators vec length
							0 * MAX_CREATOR_LEN,
						bytes: creatorAddress,
					},
				},
			],
		},
	);

	const decodedAccounts = [];
	const unverifiedAccounts = [];
	for (let i = 0; i < metadataAccounts.length; i++) {
		const e = metadataAccounts[i];
		const decoded = await decodeMetadata(e.account.data);
		const accountPubkey = e.pubkey;
		const store = [decoded, accountPubkey];
		decodedAccounts.push(store);
		for (let x of decoded.data.creators) {
			if (x.verified === 0) {
				unverifiedAccounts.push(store);
			}
		}
	}
	return [decodedAccounts, unverifiedAccounts];
}

async function decodeMetadata(buffer) {
	try {
		return await borsh.deserializeUnchecked(METADATA_SCHEMA, Metadata, buffer);
	} catch (err) {
		// console.log(err); // ignore
	}
}
